// 个人信息
// Joyce.ren 2021-05-19
<template>
  <a-layout class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <page-head title="个人信息" />
    <a-spin :spinning="spinning">
      <div class="body flex-column">
        <span class="rows flex-row justify-between">
          <div class="flex-row">
            <div class="name">
              <headUpload
                :image="obj.avatarUrl"
                :action="action"
                :data="logoData"
                :maxHeight="100"
                :maxWidth="100"
                v-on:callBack="imageUpload"
              ></headUpload>
            </div>
            <div>
              <div class="value" v-if="!name.edit">
                <div class="row flex-column flex-wrap">
                  {{ obj.name }}
                </div>
              </div>
              <div class="row flex-column flex-wrap" v-if="obj.userIntegral">
                积分： {{ obj.userIntegral }}
              </div>
            </div>
            <div class="flex-column">
              <a-input
                class="inputs"
                v-model:value="name.value"
                :maxlength="15"
                required
                v-if="name.edit"
              />
              <div class="row flex-row">
                <a-button
                  class="Cbtns"
                  v-if="name.edit"
                  @click="cancelClick('name')"
                  >取消
                </a-button>
                <a-button
                  type="primary"
                  class="Cbtns"
                  v-if="name.edit"
                  @click="affirmClick('name')"
                  >确定</a-button
                >
              </div>
              <div class="row flex-column flex-wrap" v-if="obj.userIntegral">
                积分： {{ obj.userIntegral }}
              </div>
            </div>
            <EditOutlined
              class="editbtn"
              @click="editClick('name')"
              v-if="!name.edit"
            />
          </div>
          <div class="iconButton">
            <div class="row flex-row">
              <span class="DHText">使用 : {{ obj.usedDay }} 天</span>
            </div>
            <div>
              <a-button class="DHbtn" disabled title="敬请期待">兑换</a-button>
            </div>
          </div>
        </span>
        <page-head title="基础信息" />
        <span class="rows flex-row justify-between">
          <div class="flex-row">
            <div class="name">手机号:</div>
            <div class="value" v-if="!userPhone.edit">
              <div class="value" v-if="!userPhone.edit">
                {{ obj.phoneNumber }}
              </div>
            </div>
            <div class="flex-column">
              <div class="value POrEText" v-if="userPhone.edit">
                完善信息后可用新手机号验证登录，当前手机号：{{
                  obj.phoneNumber
                }}
              </div>
              <div v-if="oldPhone">
                <a-input
                  :disabled="disabled"
                  class="input"
                  v-model:value="obj.phoneNumber"
                  :maxlength="11"
                  required
                  v-if="userPhone.edit"
                />
                <a-input
                  class="input"
                  v-model:value="userPhone.checkCode"
                  :maxlength="6"
                  placeholder="请输入手机验证码"
                  required
                  v-if="userPhone.edit"
                >
                  <template #suffix>
                    <button
                      class="btnGetCheckCode"
                      v-if="userPhone.buttonVisable"
                      @click="GetCheckCode('Phone')"
                    >
                      获取验证码
                    </button>
                    <span
                      class="btnGetCheckCode"
                      v-if="!userPhone.buttonVisable"
                      >{{ userPhone.countDown }} 秒后重发</span
                    >
                  </template>
                </a-input>
              </div>
              <div v-if="newPhone">
                <a-input
                  class="input"
                  v-model:value="userPhone.value"
                  :maxlength="11"
                  placeholder="请输入新的手机号"
                  required
                  v-if="userPhone.edit"
                />
                <a-input
                  class="input"
                  v-model:value="userPhone.checkCode"
                  :maxlength="6"
                  placeholder="请输入手机验证码"
                  required
                  v-if="userPhone.edit"
                >
                  <template #suffix>
                    <button
                      class="btnGetCheckCode"
                      v-if="userPhone.buttonVisable"
                      @click="GetCheckCode('Phone')"
                    >
                      获取验证码
                    </button>
                    <span
                      class="btnGetCheckCode"
                      v-if="!userPhone.buttonVisable"
                      >{{ userPhone.countDown }} 秒后重发</span
                    >
                  </template>
                </a-input>
              </div>
              <div v-if="oldPhone" class="row flex-row">
                <a-button
                  class="Cbtn"
                  v-if="userPhone.edit"
                  @click="cancelClick('userPhone')"
                >
                  取消
                </a-button>
                <a-button
                  type="primary"
                  class="Cbtn"
                  v-if="userPhone.edit"
                  @click="phoneChange('userPhone')"
                  >下一步</a-button
                >
              </div>
              <div v-if="newPhone" class="row flex-row">
                <a-button
                  class="Cbtn"
                  v-if="userPhone.edit"
                  @click="cancelClick('userPhone')"
                >
                  取消
                </a-button>
                <a-button
                  type="primary"
                  class="Cbtn"
                  v-if="userPhone.edit"
                  @click="affirmClick('userPhone')"
                  >确定</a-button
                >
              </div>
            </div>
          </div>
          <div class="iconButton">
            <a
              class="value"
              @click="editClick('userPhone')"
              v-if="!userPhone.edit"
              >修改</a
            >
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex-row" style="width: 60%">
            <div class="name">邮箱:</div>
            <div class="value" v-if="!userEmail.edit">
              {{ obj.email }}
            </div>
            <a-input
              class="input"
              v-model:value="userEmail.value"
              v-if="userEmail.edit"
              :maxlength="40"
            />
          </div>
          <div class="iconButton">
            <a v-if="obj.email === ''"
              ><p v-if="isShow" @click="editClick('userEmail')">绑定</p></a
            >
            <a class="value" v-if="obj.email !== ''"
              ><p v-if="isShow" @click="editClick('userEmail')">修改</p></a
            >
            <div class="icon">
              <CheckOutlined
                class="affirm"
                @click="affirmClick('userEmail')"
                v-if="userEmail.edit"
              />
              <CloseOutlined
                @click="cancelClick('userEmail')"
                v-if="userEmail.edit"
              />
            </div>
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex-row">
            <div class="name">性别:</div>
            <div class="value" v-if="!userSex.edit">
              <a-radio-group @change="userSexChange" v-model:value="obj.gender">
                <a-radio
                  v-for="r in userSex.list"
                  :key="r.linkKey"
                  :value="r.linkKey"
                  >{{ r.name }}</a-radio
                >
              </a-radio-group>
            </div>
          </div>
          <a-button
            class="btnUpdate"
            v-if="userSex.buttonVisable"
            @click="affirmClick('userSex')"
            >保存</a-button
          >
        </span>
        <page-head title="隐私设置" />
        <span class="rows flex-row justify-between">
          <div class="flex-row">
            <div class="name">密码:</div>
            <div class="value">
              {{ obj.hasPassword == true ? "*******" : "" }}
            </div>
          </div>
          <div class="iconButton">
            <a class="value" @click="editClick('userPassword')">修改</a>
          </div>
        </span>
      </div>
    </a-spin>
    <!-- 修改密码弹窗 -->
    <a-modal
      v-model:visible="userPassword.edit"
      title="修改密码"
      centered
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      @ok="UpdatePassword"
      @cancel="cancelClick('userPassword')"
    >
      <a-spin :spinning="userPassword.spinning">
        <div class="passwordView">
          <span class="rows flex-row align-center">
            <div class="name">新密码：</div>
            <div class="value">
              <input
                type="password"
                name="userPassword.newValue"
                style="display: none"
              />
              <a-input-password
                autocomplete="new-password"
                class="input"
                v-model:value="userPassword.newValue"
                :maxlength="14"
                placeholder="请输入新密码"
              />
            </div>
          </span>
          <span class="rows flex-row align-center">
            <div class="name">确认密码：</div>
            <div class="value">
              <input
                type="password"
                name="userPassword.againValue"
                style="display: none"
              />

              <a-input-password
                autocomplete="new-password"
                class="input"
                v-model:value="userPassword.againValue"
                :maxlength="14"
                placeholder="请再次输入新密码"
              />
            </div>
          </span>
        </div>
      </a-spin>
    </a-modal>
  </a-layout>
</template>
<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'
import headUpload from '@/components/headUpload.vue'
// import { EditOutlined } from '@ant-design/icons-vue'
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { getProfileDetail } from '@/api/IdentityAPI'
// import ContentTitle from '../components/ContentTitle'
// import { isEmpty } from '@/assets/common.js'

const userId = '39fc90eb-91d4-2d9c-7efc-f6313d0d1488'

export default defineComponent({
  components: {
    'page-head': PageHead,
    // 'content-title': ContentTitle,
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    headUpload,
  },
  data () {
    return {
      objsInput: {},
      objs: {},
      newPhone: false,
      oldPhone: true,
      disabled: true,
      isShow: true,
      logoData: {
        userId: userId,
        type: 'head',
      }, // logo上传时的data
      action: '/UploadUserLogoExtend', // 上传路径
      spinning: false, // 是否在加载
      obj: {}, // 账户信息
      minHeight: 0, // 最小高度

      name: {
        edit: false,
        value: '',
      }, // 账号名称
      userPhone: {
        edit: false,
        value: '',
        checkCode: '',
        buttonVisable: true,
        countDown: null,
        timer: null,
      }, // 账户手机
      userEmail: {
        edit: false,
        value: '',
        checkCode: '',
        buttonVisable: true,
        countDown: null,
        timer: null,
      }, // 邮箱
      userSex: {
        value: '',
        list: [],
        buttonVisable: false,
      }, // 性别
      addWay: {
        value: '',
        list: [],
        buttonVisable: false,
      }, // 性别
      userPassword: {
        spinning: false, // 是否在加载
        edit: false,
        newValue: '',
        againValue: '',
      }, // 密码
      contactWay: {
        value: '',
        list: [],
        buttonVisable: false,
      }, // 联系信息展示方式
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.getGenderData() // 获取性别信息
    this.getUserInfoData() // 获取账户信息
    // this.getAddwayData() // 获取添加方式信息
    // this.getContactWayData()
  },
  // 方法
  mounted () {
    window.addEventListener('setItemEvent', (e) => {
      if (e.key === 'userInfo') {
        const data = JSON.parse(e.newValue)
        this.obj = Object.assign({}, data)
      }
    })
  },
  methods: {
    // logo上传回调事件
    imageUpload (e) {
      this.obj.userHeadUrl = e
      getProfileDetail().then(data => {
        if (data.status === 1) {
          this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
          window.location.href = '/account/login'
          window.localStorage.clear()
        }
        localStorage.setItem('userInfo', JSON.stringify(data))
      })
    },
    // 获取账户信息
    getUserInfoData () {
      this.spinning = true
      this.isShow = true
      api
        .get('/api/app/app-profile', {
        })
        .then(({ data }) => {
          this.spinning = false
          this.obj = data
          if (data.status === 1) {
            this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
            window.location.href = '/account/login'
            window.localStorage.clear()
          }
          localStorage.setItem('userInfo', JSON.stringify(data))
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取账户信息失败' + err)
          console.log(err)
        })
    },
    // 获取性别信息
    getGenderData () {
      this.spinning = true
      api
        .get('/api/app/method/gender-select-list')
        .then(({ data }) => {
          this.spinning = false
          this.userSex.list = data
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取性别信息失败' + err)
          console.log(err)
        })
    },
    // 获取验证码
    GetCheckCode (type) {
      const obj = {}
      switch (type) {
        case 'Phone':
          if (this.oldPhone === true) {
            obj.phone = this.obj.phoneNumber
          } else {
            if (this.userPhone.value === this.obj.phoneNumber) {
              this.$message.error('输入手机号码与原手机一致，请重新输入')
              return
            }
            if (this.userPhone.value === '' || this.userPhone.value === undefined) {
              this.$message.error('请先填写手机号')
              return
            }
            obj.phone = this.userPhone.value
          }
          break
      }
      api
        .post('/api/app/account/send-verifycation-code', obj)
        .then(({ data }) => {
          this.spinning = false
          this.countDown(type)
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取验证码失败' + err)
          console.log(err)
        })
    },
    // 倒计时
    countDown (type) {
      const TIME_COUNT = 60
      switch (type) {
        case 'Phone':
          this.userPhone.countDown = TIME_COUNT
          this.userPhone.buttonVisable = false
          this.userPhone.timer = setInterval(() => {
            if (this.userPhone.countDown > 0 && this.userPhone.countDown <= TIME_COUNT) {
              this.userPhone.countDown--
            } else {
              this.userPhone.buttonVisable = true
              clearInterval(this.userPhone.timer)
              this.userPhone.timer = null
            }
          }, 1000)

          break
        case 'Email':
          this.userEmail.countDown = TIME_COUNT
          this.userEmail.buttonVisable = false
          this.userEmail.timer = setInterval(() => {
            if (this.userEmail.countDown > 0 && this.userEmail.countDown <= TIME_COUNT) {
              this.userEmail.countDown--
            } else {
              this.userEmail.buttonVisable = true
              clearInterval(this.userEmail.timer)
              this.userEmail.timer = null
            }
          }, 1000)

          break
      }
    },
    // 性别值改变
    userSexChange (e) {
      this.userSex.value = e.target.value
      if (e.target.value !== this.obj.userSex) {
        this.userSex.buttonVisable = true
      } else {
        this.userSex.buttonVisable = false
      }
    },
    // 添加方式改变
    addWayChange (e) {
      if (e.target.value !== this.obj.addWay) {
        this.addWay.buttonVisable = true
      } else {
        this.addWay.buttonVisable = false
      }
    },
    // 手机/邮箱值改变
    contactWayChange (e) {
      if (e.target.value !== this.obj.isShowPOrE) {
        this.contactWay.buttonVisable = true
      } else {
        this.contactWay.buttonVisable = false
      }
    },
    // 编辑按钮
    editClick (type) {
      switch (type) {
        case 'name':
          this.name.edit = true
          this.name.value = this.obj.name
          break
        case 'userPhone':
          this.oldPhone = true
          this.userPhone.edit = true
          this.userPhone.value = this.obj.userPhone
          break
        case 'userEmail':
          this.isShow = false
          this.userEmail.edit = true
          this.userEmail.value = this.obj.email
          break
        case 'userPassword':
          this.userPassword.edit = true
          break
        case 'addWay':
          this.addWay.edit = true
          this.addWay.value = this.obj.addWay
          break
      }
    },
    // 确认按钮
    affirmClick (type) {
      this.objs = this.obj
      switch (type) {
        case 'name':
          if (this.name.value === '') {
            this.$message.error('请输入账户名称')
            return
          }
          if (this.name.value === this.obj.name) {
            this.$message.error('账户名称未发生变动')
            return
          }
          this.objs.name = this.name.value
          break
        case 'userPhone':
          this.phoneChange('userPhone')
          this.objs.phoneNumber = this.userPhone.value
          this.objs.userName = this.userPhone.value
          break
        case 'userEmail':
          if (this.userEmail.value === '') {
            this.$message.error('请输入邮箱')
            return
          }
          if (this.userEmail.value === this.obj.email) {
            this.$message.error('邮箱未发生变动')
            return
          }
          this.objs.email = this.userEmail.value
          break
        case 'addWay':
          this.objs.addWay = this.addWay.value
          break
        case 'userSex':
          this.objs.gender = this.userSex.value
          break
        case 'contactWay':
          this.objs.isShowPOrE = this.contactWay.value
          break
      }
      this.submitUpdateData(this.objs, type)
    },
    // 电话修改
    phoneChange (type) {
      const obj = {
        id: this.obj.id,
      }
      switch (type) {
        case 'userPhone':
          if (this.userPhone.value === '') {
            this.$message.error('请输入手机号码')
            return
          }
          if (this.userPhone.checkCode === '') {
            this.$message.error('请填写手机验证码')
            return
          }
          if (this.oldPhone === true) {
            obj.key = this.obj.phoneNumber
          } else {
            obj.key = this.userPhone.value
          }
          obj.checkCode = this.userPhone.checkCode
          break
      }
      this.spinning = true
      api
        .post('/api/app/method/verification-check-code', obj)
        .then(({ data }) => {
          this.$message.success('验证成功')
          this.userPhone.checkCode = ''
          this.spinning = false
          if (this.oldPhone === true) {
            this.oldPhone = false
            this.newPhone = true
            this.userPhone.buttonVisable = true
            // this.countDown(type)
          } else {
            this.cancelClick(type)
            this.successBefore(type)
            this.getUserInfoData()
          }
          // this.userPhone.edit = false
          // this.cancelClick(type)
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败' + err)
          console.log(err)
        })
    },
    // 密码修改
    UpdatePassword () {
      if (this.userPassword.newValue === '' || this.userPassword.againValue === '') {
        this.$message.error('新密码不能为空')
        return
      }
      if (this.userPassword.newValue !== this.userPassword.againValue) {
        this.$message.error('两次输入的密码不一致，请重新输入')
        return
      }
      this.userPassword.spinning = true
      const obj = {
        id: this.obj.id,
        newPassword: this.userPassword.newValue,
        concurrencyStamp: this.obj.concurrencyStamp,
      }
      api
        .post('/api/app/app-profile/change-password', obj)
        .then(({ data }) => {
          this.$message.success('修改成功')
          this.userPassword.spinning = false
          this.userPassword.edit = false
          this.userPassword.newValue = ''
          this.userPassword.againValue = ''
          this.getUserInfoData()
        })
        .catch(err => {
          console.log(err)
          this.userPassword.spinning = false
          // this.$message.error('操作失败:输入格式不正确,请重新输入')
          console.log(err)
        })
    },
    // 取消按钮
    cancelClick (type) {
      switch (type) {
        case 'name':
          this.name.edit = false
          this.name.value = this.obj.name
          break
        case 'userPhone':
          this.userPhone.edit = false
          this.userPhone.value = this.obj.userPhone
          this.userPhone.checkCode = ''
          this.oldPhone = true
          this.newPhone = false
          break
        case 'userEmail':
          this.userEmail.edit = false
          this.isShow = true
          this.userEmail.value = this.obj.email
          this.userPhone.checkCode = ''
          break
        case 'userPassword':
          this.userPassword.edit = false
          this.userPassword.newValue = ''
          this.userPassword.againValue = ''
          break
      }
    },
    // 成功后事件
    successBefore (type) {
      switch (type) {
        case 'name':
          this.name.buttonVisable = true
          break
        case 'userPhone':
          this.userPhone.buttonVisable = true
          this.userPhone.timer = null
          clearInterval(this.userPhone.timer)
          break
        case 'userEmail':
          this.userEmail.buttonVisable = true
          this.userEmail.timer = null
          clearInterval(this.userEmail.timer)
          break
        case 'userSex':
          this.userSex.buttonVisable = false
          break
        case 'addWay':
          this.addWay.buttonVisable = false
          break
        case 'contactWay':
          this.contactWay.buttonVisable = false
          break
      }
    },
    // 提交
    submitUpdateData (objs, type) {
      this.spinning = true
      this.newPhone = false
      api
        .put('/api/app/app-profile', objs)
        .then(({ data }) => {
          if (data.status === 1) {
            this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
            window.location.href = '/account/login'
            window.localStorage.clear()
          }
          this.$message.success('修改成功')
          getProfileDetail().then(data => {
            if (data.status === 1) {
              this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
              window.location.href = '/account/login'
              window.localStorage.clear()
            }
            localStorage.setItem('userInfo', JSON.stringify(data))
          })
          this.cancelClick(type)
          this.successBefore(type)
          this.getUserInfoData()
          this.spinning = false
        })
        .catch(err => {
          this.getUserInfoData()
          this.cancelClick(type)
          this.spinning = false
          console.log(err)
          // this.$message.error('操作失败' + err)
        })
    },
  },
})
</script>
<style  lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  height: 100%;
  background: #fff;
}
.body {
  // margin-left: 15px;
  .rows {
    width: 80%;
    margin: 10px 10px 10px 25px;
    // margin: 10px;
    min-height: 40px;
    line-height: 40px;
    // font-size: 16px;
    .name {
      width: 168px;
    }
    .iconButton {
      cursor: pointer;
      margin-left: 20px;
      .icon {
        margin-top: 10px;
      }
      // margin-top: 10px;
      .affirm {
        margin-right: 20px;
      }
      .close {
        margin-left: 20px;
      }
    }
    .value {
      min-width: 100px;
    }
    .input {
      height: 40px;
      line-height: 40px;
      // font-size: 16px;
      width: 450px;
      margin-top: 10px;
      border-radius: 4px;
    }
    .inputs {
      height: 40px;
      line-height: 40px;
      // font-size: 16px;
      width: 150px;
      margin-top: 10px;
    }
    .Cbtns {
      height: 34px;
      line-height: 34px;
      // font-size: 16px;
      width: 60%;
      margin-top: 10px;
      margin-right: 5%;
      border-radius: 4px;
    }
    .Cbtn {
      height: 34px;
      line-height: 34px;
      // font-size: 16px;
      width: 15%;
      margin-top: 10px;
      margin-right: 5%;
      border-radius: 4px;
    }
    .btnUpdate {
      border: 0px;
      color: #1890ff;
      // font-size: 16px;
      background-color: rgb(255, 255, 255);
      margin: 0px;
      padding: 0px;
    }
    .editbtn {
      margin-top: 12px;
    }
    .DHText {
      font-size: 14px;
    }
    .DHbtn {
      border-radius: 5px;
      // font-size: 15px;
      width: 100%;
    }
    .btnGetCheckCode {
      border: 0px;
      font-size: 14px;
      background-color: #fff;
      color: blue;
    }
    .select {
      height: 40px;
      line-height: 40px;
      // font-size: 16px;
      width: 450px;
    }
  }
}

.passwordView {
  .rows {
    margin: 10px;
    min-height: 40px;
    line-height: 40px;
    // font-size: 16px;
    .name {
      width: 83px;
    }
    .value {
      min-width: 100px;
    }
    .input {
      height: 40px;
      line-height: 40px;
      // font-size: 16px;
      width: 300px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
.ant-upload {
  img {
    width: 180px;
    height: 180px;
  }
}
</style>
